import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AppProviderContextType } from './AppProvider.types';

import { auth, signIn } from '../services/firebase';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import {
  getUserSubscriptions,
  INSIGHTS_STATUS,
  InstagramUserData,
  InstagramUserDataCategory,
  sendPDFReportData,
} from '../services/requests';
import {
  PDF_COURSE_SUBSCRIPTION_PLAN_ID,
  STORAGE_PDF_REPORT_SENDED_KEY,
  STORAGE_USERNAME_KEY,
} from '../utils/constants';
import { resolveUserCredentials } from '../utils/credentials';
import logger from '../utils/logger';
import firebaseAuthErrors from '../constants/firebase-auth-errors';
import { Nullable } from '../models/common';

export const AppContext = createContext<AppProviderContextType | null>(null);

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLogOutModalOpen, setLogOutModalOpen] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(true);

  const [isRestorePasswordModalOpen, setRestorePasswordModalOpen] =
    useState(false);

  const [isSWWModalOpen, setSWWModalOpen] = useState(false);

  const [isPdfCoursePurchaseModalOpen, setPdfCoursePurchaseModalOpen] = useState(false);

  const [isShowProcessInsightsModal, setIsShowProcessingInsightsModal] =
    useState(false);

  const [isSignInLoading, setSignInLoading] = useState(false);

  const [isUserAuthorized, setUserAuthorized] = useState(false);

  const [isHasPdfCourseSubscription, setHasPdfCourseSubscription] = useState(false);

  const [subscriptionId, setSubscriptionId] = useState<Nullable<string>>(null);

  const [isSubscriptionCanceled, setSubscriptionCanceled] = useState(false);

  const [instagramUserData, setInstagramUserData] =
    useState<InstagramUserData | null>(null);

  const [cathegoryData, setCathegoryData] = useState<
    InstagramUserDataCategory['items'] | null
  >(null);

  const [firebaseUserCredential, setFirebaseUserCredential] =
    useState<User | null>(null);

  const signInCredentials = useMemo(
    () =>
      resolveUserCredentials(
        new URLSearchParams(window.location.search).get('creds')
      ),
    []
  );

  const [authenticationErrorMessage, setAuthenticationErrorMessage] =
    useState('');

  const checkUserSubscription = async (token: string) => {
    setSignInLoading(true);

    getUserSubscriptions({ token })
      .then(async (response) => {

        const isHasPdfCourseSubscription = response.subscriptions.find(
          (subscription) => {
            return (
              subscription.bundle === 'com.huynh.socialmetric' &&
              subscription.plan_code.includes(PDF_COURSE_SUBSCRIPTION_PLAN_ID) &&
              subscription.status !== 'expired'
            );
          }
        );
        if (isHasPdfCourseSubscription) {
          setHasPdfCourseSubscription(true);
        }

        const activeSubscription = response.subscriptions.find(
          (subscription) => {
            return (
              subscription.bundle === 'com.huynh.socialmetric' &&
              ['active', 'canceled'].includes(subscription.status)
            );
          }
        );

        if (activeSubscription) {
          setUserAuthorized(true);
          setSubscriptionId(activeSubscription.id);
          setSubscriptionCanceled(activeSubscription.status === 'canceled');
        } else {
          await logger(
            new URLSearchParams({
              source: 'check_active_subscription',
              creds: JSON.stringify(signInCredentials),
              subscriptions: JSON.stringify(response.subscriptions),
            })
          );

          setSWWModalOpen(true);
        }

        setSignInLoading(false);
      })
      .catch(() => {
        setSignInLoading(false);
      });
  };

  const handleLogIn = async (email: string, password: string) => {
    setSignInLoading(true);
    setAuthenticationErrorMessage('');

    signIn(email, password)
      .then(async (userCredential) => {
        if (userCredential?.user) {
          setSignInLoading(true);
          checkUserSubscription(userCredential.user.uid);
          setFirebaseUserCredential(userCredential.user);
        } else {
          setSignInLoading(false);

          await logger(
            new URLSearchParams({
              source: 'sign_in',
              message: 'user_is_not_found',
            })
          );
        }
      })
      .catch(async (err) => {
        setSignInLoading(false);

        const authErrorMessage = err.code
          ? firebaseAuthErrors[err.code as keyof typeof firebaseAuthErrors]
          : 'Something went wrong';

        setAuthenticationErrorMessage(authErrorMessage);

        await logger(
          new URLSearchParams({
            source: 'sign_in_error',
            message: String(authErrorMessage),
            data: JSON.stringify({ email, password }),
          })
        );
      });
  };

  useEffect(() => {
    if (signInCredentials) {
      handleLogIn(signInCredentials.email, signInCredentials.password);
    }
  }, [signInCredentials]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && !signInCredentials) {
        checkUserSubscription(user.uid);
        setFirebaseUserCredential(user);
      } else {
        setUserAuthorized(false);
        window.localStorage.removeItem(STORAGE_USERNAME_KEY);
      }
    });
  }, [auth, signInCredentials]);

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      })
      .finally(() => {
        setLogOutModalOpen(false);
      });
  };

  const handleSendPDFReportData = (username: string, tries = 2) => {
    const isPdfDataSended =
      window.localStorage.getItem(STORAGE_PDF_REPORT_SENDED_KEY) === 'true';
    if (firebaseUserCredential !== null && !isPdfDataSended) {
      sendPDFReportData({
        username,
        email: firebaseUserCredential.email,
        uid: firebaseUserCredential.uid,
      })
        .then((resp) => {
          window.localStorage.setItem(STORAGE_PDF_REPORT_SENDED_KEY, 'true');
        })
        .catch((err) => {
          console.log('sendPDFReportData error', err);
          if (err.status === 401 || err.status === 403) {
            window.localStorage.setItem(STORAGE_PDF_REPORT_SENDED_KEY, 'true');
          }
          if (err.status === 500) {
            if (tries >= 1) {
              handleSendPDFReportData(username, tries - 1);
            }
          }
        });
    }
  };

  const value: AppProviderContextType = {
    firebaseUserCredential,
    subscriptionId,
    setIsShowProcessingInsightsModal,
    isShowProcessInsightsModal,
    authenticationErrorMessage,
    handleLogIn,
    handleLogOut,
    isLogOutModalOpen,
    setLogOutModalOpen,
    isSignInLoading,
    setSignInLoading,
    isUserAuthorized,
    isSWWModalOpen,
    setSWWModalOpen,
    instagramUserData,
    setInstagramUserData,
    cathegoryData,
    setCathegoryData,
    isRestorePasswordModalOpen,
    setRestorePasswordModalOpen,
    showUpdateModal,
    setShowUpdateModal,
    handleSendPDFReportData,
    isHasPdfCourseSubscription,
    isPendingInsights: instagramUserData?._insights?.status === INSIGHTS_STATUS.PENDING,
    isProcessingInsights: instagramUserData?._insights?.status === INSIGHTS_STATUS.PROCESSING,
    isCompletedInsights: instagramUserData?._insights?.status === INSIGHTS_STATUS.COMPLETED,
    isPdfCoursePurchaseModalOpen,
    setPdfCoursePurchaseModalOpen,
    checkUserSubscription,
    isSubscriptionCanceled,
    setSubscriptionCanceled,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
