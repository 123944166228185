import React, { useState } from 'react';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import background_image from '../../assets/images/dashboard/pdfLessons/background_image.webp';
import gradient_background from '../../assets/images/dashboard/pdfLessons/gradient_background.webp';
import { ReactComponent as GetPdfCourse } from '../../assets/svg/get_pdf_course.svg';
import { ReactComponent as Svg1 } from '../../assets/svg/get_pdf_course_m1.svg';
import { ReactComponent as Svg2 } from '../../assets/svg/get_pdf_course_m2.svg';
import { ReactComponent as Svg3 } from '../../assets/svg/get_pdf_course_m3.svg';
import BaseButton from '../Dashboard/components/Button';
import { createSubscription } from '../../services/requests';
import { AppRoutes, PDF_COURSE_SUBSCRIPTION_PLAN_ID } from '../../utils/constants';
import { useAppState } from '../../providers/AppProvider.hooks';
import { useNavigate } from 'react-router-dom';

function PdfCoursePurchaseModal({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: (state: boolean) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { firebaseUserCredential, checkUserSubscription } = useAppState();
  const navigate = useNavigate();

  const handleGetClick = () => {
    if (loading) return;
    if (firebaseUserCredential && firebaseUserCredential.email) {
      setLoading(true);

      createSubscription({
        plan_id: PDF_COURSE_SUBSCRIPTION_PLAN_ID,
        email: firebaseUserCredential.email,
        firebase_id: firebaseUserCredential.uid,
      })
        .then(async (response) => {
          if ('error' in response) {
            setError(response.error);
          }
          if ('id' in response) {
            await checkUserSubscription(firebaseUserCredential.uid);
            setModalOpen(false);
            navigate(AppRoutes.PDF_COURSE);
          }
        })
        .catch(async (error) => {
          let parsedErrorMessage = '';
          try {
            const errorJson = await error.json();
            if ('error' in errorJson) {
              parsedErrorMessage = errorJson.error;
            }
          } catch (err) {
            console.log(err);
          }
          const errorMessage = parsedErrorMessage !== '' ? parsedErrorMessage : error;
          setError(JSON.stringify(errorMessage));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const offer = {
    sum: 28.99,
    fullPrice: 69.99,
    discount: 57,
  };

  return (
    <Modal open={isModalOpen}>
      <Box
        sx={{
          background: `url(${background_image}), url(${gradient_background})`,
          backgroundSize: 'contain, cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '100%',
          maxWidth: '460px',
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 20px 20px',
            height: '100%',
          }}
        >
          <Box
            sx={{
              background: '#fff',
              borderRadius: '20px',
              width: '100%',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              '& .option': {
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',

                svg: {
                  minWidth: '24px',
                  minHeight: '24px',
                },
                '& .title': {
                  lineHeight: '16px',
                  color: '#232631',
                },
              },
            }}
          >
            <GetPdfCourse />
            <Box
              sx={{
                marginTop: '0',
                fontWeight: '700',
                fontSize: '24px',
                textAlign: 'left',
                lineHeight: '28px',
                span: {
                  color: '#ef2626',
                },
              }}
            >
              Strategies to <span>Boost Engagement</span> on Your Instagram Profile
            </Box>
            <Box className="option">
              <Svg1 />
              <Typography className="title">Detailed pdf lessons for creating a standout Instagram profile</Typography>
            </Box>
            <Box className="option">
              <Svg2 />
              <Typography className="title">Learn how to attract and retain your target audience</Typography>
            </Box>
            <Box className="option">
              <Svg3 />
              <Typography className="title">Boost follower engagement with proven strategies</Typography>
            </Box>
            <Box
              sx={{
                borderTop: '1px solid rgba(35, 38, 49, 0.1)',
                width: '100%',
                marginTop: '5px',
                paddingTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                '& .price': {
                  fontSize: '14px',
                  lineHeight: 'normal',
                  color: '#8c8e96',
                  fontWeight: 400,
                  textAlign: 'center',
                  marginTop: 0,
                },
              }}
            >
              <Typography className="price">One-time price offer: ${offer.sum}!</Typography>
              <Typography className="price">
                Full price: ${offer.fullPrice}.{' '}
                <span style={{ color: '#56AC14', fontWeight: 600 }}>Save {offer.discount}%!</span>
              </Typography>
            </Box>

            {error !== '' && (
              <Box
                sx={{
                  textAlign: 'center',
                  width: '100%',

                  span: {
                    color: 'red',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '22px',
                  },
                }}
              >
                <span>{error}</span>
              </Box>
            )}
            <BaseButton disabled={loading} onClick={handleGetClick}>
              {loading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: '#fff',
                  }}
                />
              ) : (
                'Get my course'
              )}
            </BaseButton>
          </Box>
          <Box
            sx={{
              WebkitTapHighlightColor: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              color: '#fff',
              textDecoration: 'underline',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: 'normal',
              marginTop: '15px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            No, i don’t want to get course
          </Box>
          <Box
            sx={{
              marginTop: '10px',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: 'normal',
              fontFamily: 'Avenir Next',
              textAlign: 'center',
              color: '#FFF',
              opacity: 0.6,
            }}
          >
            *On click, your account will be charged one payment of ${offer.sum} for the course
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default PdfCoursePurchaseModal;
