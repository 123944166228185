import React, { useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import BaseModal from './BaseModal';
import canceled from '../../assets/images/dashboard/unsubscribe/canceled.svg';
import heart from '../../assets/images/dashboard/unsubscribe/heart.webp';
import svg1 from '../../assets/images/dashboard/unsubscribe/svg1.svg';
import svg2 from '../../assets/images/dashboard/unsubscribe/svg2.svg';
import svg3 from '../../assets/images/dashboard/unsubscribe/svg3.svg';
import { useAppState } from '../../providers/AppProvider.hooks';
import { cancelSubscription } from '../../services/requests';

function CancelSubscriptionModal({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: (state: boolean) => void;
}) {
  const { subscriptionId, isSubscriptionCanceled, setSubscriptionCanceled } = useAppState();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const theme = useTheme();

  const handleCancelSubscription = () => {
    if (isLoading) return;

    if (subscriptionId) {
      setLoading(true);
      cancelSubscription(subscriptionId)
        .then(async (response) => {
          if ('error' in response) {
            setError(response.error);
          } else {
            if (response.success) {
              setSubscriptionCanceled(true);
            } else {
              setError('Something went wrong');
            }
          }
        })
        .catch(async (error) => {
          console.log('cancelSubscription error', error);
          let parsedErrorMessage = '';
          try {
            const errorJson = await error.json();
            if ('error' in errorJson) {
              parsedErrorMessage = errorJson.error;
            }
          } catch (err) {
            console.log(err);
          }
          const errorMessage = parsedErrorMessage !== '' ? parsedErrorMessage : error;
          setError(typeof errorMessage === 'object' ? 'Something went wrong' : JSON.stringify(errorMessage));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return isSubscriptionCanceled ? (
    <BaseModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} showClose={false}>
      <>
        <img src={canceled} alt="logo"></img>
        <Typography
          sx={{
            color: '#232631',
            fontWeight: 600,

            lineHeight: 'normal',
            fontSize: '20px',
            marginTop: '16px',
          }}
        >
          Your subscription was canceled
        </Typography>

        <Typography
          sx={{
            color: '#232631',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            marginTop: '4px',
            marginBottom: '16px',
          }}
        >
          {/* Your plan is active until {'date'}. Thanks for using our product! */}
          Your current plan remains active till the end of the paid period. Thanks for choosing us!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            '& button': {
              border: 'none',
              borderRadius: '12px',
              background: theme.palette.common.blue,
              padding: '19px 32px',
              color: '#fff',
              fontWeight: 600,
              lineHeight: 'normal',
              fontSize: '16px',
              width: '100%',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              WebkitTapHighlightColor: 'transparent',
            },
          }}
        >
          <button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Continue
          </button>
        </Box>
      </>
    </BaseModal>
  ) : (
    <BaseModal isModalOpen={isModalOpen} setModalOpen={setModalOpen}>
      <>
        <img width={105} height={77} src={heart} alt="heart"></img>
        <Typography
          sx={{
            color: '#232631',
            fontWeight: 600,

            lineHeight: 'normal',
            fontSize: '20px',
            marginTop: '16px',
          }}
        >
          We are sorry to see you go
        </Typography>

        <Typography
          sx={{
            color: '#232631',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            marginTop: '4px',
            marginBottom: '16px',
          }}
        >
          If you cancel your membership, you’ll be missing out on all this cool stuff.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            '& .wrapper': {
              display: 'flex',
              gap: '15px',

              '& div': {
                background: 'rgba(37, 107, 253, 0.1)',
                borderRadius: '50%',
                minWidth: '56px',
                minHeight: '56px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
              p: {
                fontSize: '16px',
                color: theme.palette.text.primary,
                fontWeight: 400,
                fontFamily: 'Avenir Next',
                textAlign: 'left',
                lineHeight: 'normal',
                span: {
                  fontWeight: 600,
                  color: theme.palette.common.blue,
                },
              },
            },
          }}
        >
          <Box className="wrapper">
            <Box>
              <img src={svg1} alt="point"></img>
            </Box>
            <Typography>
              <span>Track</span> followers, unfollowers, likes, and viewers in real time.
            </Typography>
          </Box>

          <Box className="wrapper">
            <Box>
              <img src={svg2} alt="point"></img>
            </Box>
            <Typography>
              <span>Get insights</span> into follower growth and post performance.
            </Typography>
          </Box>

          <Box className="wrapper">
            <Box>
              <img src={svg3} alt="point"></img>
            </Box>
            <Typography>
              <span>Identify</span> users who engage with your account but don’t follow you.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px',
            '& button': {
              border: 'none',
              borderRadius: '12px',
              background: theme.palette.common.blue,
              padding: '19px 32px',
              color: '#fff',
              fontWeight: 600,
              lineHeight: 'normal',
              fontSize: '16px',
              width: '100%',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              WebkitTapHighlightColor: 'transparent',
            },
          }}
        >
          {error && (
            <span
              style={{
                color: 'red',
                marginBottom: '15px',
              }}
            >
              {error}
            </span>
          )}
          <button
            className="blue"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Count me in! Keep my membership
          </button>
          <Typography
            onClick={handleCancelSubscription}
            sx={{
              fontSize: '16px',
              color: theme.palette.text.secondary,
              textDecoration: 'underline',
              textDecorationColor: theme.palette.text.secondary,
              textDecorationThickness: '1px',
              fontWeight: 400,
              fontFamily: 'Avenir Next',
              lineHeight: 'normal',
              marginTop: '15px',
              cursor: 'pointer',
              WebkitTapHighlightColor: 'transparent',
            }}
          >
            {isLoading ? (
              <CircularProgress
                size={16}
                sx={{
                  color: theme.palette.text.secondary,
                }}
              />
            ) : (
              <>I want to cancel</>
            )}
          </Typography>
        </Box>
      </>
    </BaseModal>
  );
}

export default CancelSubscriptionModal;
